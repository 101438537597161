import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`18th February 2020`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">{`You can now specify any client options when building with `}<inlineCode parentName="li">{`ArmeriaRetrofitBuilder`}</inlineCode>{`, because it extends `}<inlineCode parentName="li">{`AbstractClientOptionsBuilder`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2483"
        }}>{`#2483`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Retrofit retrofit = ArmeriaRetrofit.builder("http://example.com")
                                   .factory(...)
                                   .decorator(...)
                                   .responseTimeout(...)
                                   .build();
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`FallthroughException`}</inlineCode>{` is not a part of the internal API anymore, so you can refer to it when testing your annotation service extensions. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2495"
        }}>{`#2495`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Armeria clients will not violate the `}<inlineCode parentName="li">{`MAX_CONCURRENT_STREAMS`}</inlineCode>{` setting enforced by an HTTP/2 server anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2256"
        }}>{`#2256`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2374"
        }}>{`#2374`}</a></li>
      <li parentName="ul">{`Fixed a regression where `}<inlineCode parentName="li">{`Server`}</inlineCode>{` fails to read `}<a parentName="li" {...{
          "href": "https://github.com/PKCS/armeria/issues/5"
        }}>{`PKCS#5`}</a>{` a private key since 0.98.0 `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2485"
        }}>{`#2485`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`RequestContextExporter`}</inlineCode>{` does not export an entry whose value is `}<inlineCode parentName="li">{`null`}</inlineCode>{` anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2492"
        }}>{`#2492`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`DocService`}</inlineCode>{` does not fail with a `}<inlineCode parentName="li">{`ReflectionsException`}</inlineCode>{` on startup anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2491"
        }}>{`#2491`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2494"
        }}>{`#2494`}</a></li>
      <li parentName="ul">{`Fixed some potential buffer leaks. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2497"
        }}>{`#2497`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2498"
        }}>{`#2498`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2499"
        }}>{`#2499`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2500"
        }}>{`#2500`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brave 5.9.4 → 5.9.5`}</li>
      <li parentName="ul">{`gRPC 1.27.0 → 1.27.1`}
        <ul parentName="li">
          <li parentName="ul">{`Protobuf 3.11.3 → 3.11.4`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Micrometer 1.3.3 → 1.3.5`}</li>
      <li parentName="ul">{`Tomcat 9.0.30 → 9.0.31, 8.5.50 → 8.5.51`}</li>
      <li parentName="ul">{`ZooKeeper 3.5.6 → 3.5.7`}</li>
      <li parentName="ul">{`Shaded dependencies:`}
        <ul parentName="li">
          <li parentName="ul">{`fastutil 8.3.0 → 8.3.1`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'ikhoon', 'fukuang', 'jrhee17', 'mauhiz', 'minwoox', 'themnd', 'trustin', 'yuinacor']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      